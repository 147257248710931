.main-banner {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: linear-gradient(173deg, rgba(0, 0, 0, 1) 37%, rgba(0, 60, 95, 1) 79%);
}

.main-banner h1 {
    color: #ffffff;
    margin: 30px auto;
    max-width: 300px;
    text-align: center;
}

.main-banner a {
    color: #ffffff;
    background-color: #000000;
    text-decoration: none;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 5px;
}

.main-banner a:hover {
    background: #222222;
}

.main-banner img {
    max-width: 200px;
}

.position-relative {
    position: relative;
    top: 82px;
}