.overlay-modal {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    padding: 50px 20px;
}

.modal-content {
    position: relative;
    text-align: center;
}

.modal-content h2 {
    margin: 0;
}

.modal-content p {
    margin: 0px;
    font-size: 18px;
    line-height: 22px;
}

.modal-content button {
    margin: 0;
    position: absolute;
    top: -30px;
    right: 0;
    cursor: pointer;
}

@media (max-width: 767px) {
    .modal {
        width: 70%;
    }
}