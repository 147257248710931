.workflow {
    background-color: #000000;
    padding: 80px 0px;
}

.workflow .title {
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bold;
    margin: 0 auto 30px;
    text-align: center;
}

.workflow .text {
    color: #ffffff;
    font-size: 17px;
    line-height: 23px;
    margin: 0px auto 30px;
    max-width: 860px;
    text-align: center;
}

.workflow img {
    max-width: 1100px;
    margin: 0 auto;
    display: block;
}

.overflow-image {
    margin-top: 50px;
}

.workflow .d-flex {
    display: flex;
}

.workflow a {
    display: block;
    margin: 50px auto 0px;
    text-align: center;
    background-color: #003C5F;
    color: #ffffff;
    text-decoration: none;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
}

.workflow a:hover {
    background-color: #4682B4;
}

@media (max-width: 767px) {
    .workflow {
        padding: 40px 0px;
    }

    .overflow-image {
        overflow: scroll;
    }

    .workflow a {
        margin: 40px auto 0px;
    }

    .workflow .text,
    .workflow .title {
        text-align: left;
    }
}