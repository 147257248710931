@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

body,
ul,
p,
h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

button {
  background: none;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
}

.container {
  max-width: 1100px;
  margin: 0px auto;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
    padding: 0px 20px;
  }
}