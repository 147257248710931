.quality {
    background-color: #003C5F;
    color: #ffffff;
    padding: 80px 0px;
}

.quality .title {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
    margin: 0 0 30px;
}

.quality p.description {
    font-size: 17px;
    line-height: 23px;
    margin: 0px;
    max-width: 500px;
}

.quality .d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quality .d-flex div {
    width: 50%;
}

.quality .d-flex div img {
    display: block;
    width: 100%;
}

@media (max-width: 767px) {
    .quality {
        padding: 40px 0px;
    }

    .quality .d-flex {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .quality .d-flex div {
        width: 100%;
    }
}