footer {
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 40px 0px;
    border-top: 1px solid #ffffff;
}

footer img {
    display: block;
}

footer ul {
    display: flex;
    justify-content: center;
    gap: 30px;
    list-style: none;
    margin: 30px 0px;
}

footer ul li a {
    color: #ffffff;
}

footer ul li a:hover {
    opacity: .8;
}

footer .links-sociais {
    color: #ffffff;
    margin: 0px 5px;
}

@media (max-width: 767px) {
    footer ul {
        flex-direction: column;
        gap: 15px;
    }
}