header {
    background-color: #000000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 82px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 0px;
}

nav img {
    display: block;
    max-width: 240px;
}

.menu-icon {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

header ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

header ul li a {
    color: #ffffff;
    text-decoration: underline;
    text-underline-position: under;
}

header ul li a:hover {
    opacity: .8;
}

.logo {
    color: #ffffff;
    font-weight: bold;
}

@media (max-width: 767px) {
    nav {
        padding: 20px;
    }

    .menu-icon {
        display: block;
    }

    header button {
        color: #d7d7d7;
    }

    header ul {
        display: none;
        flex-direction: column;
        width: 50%;
        position: absolute;
        top: 82px;
        right: 0;
        padding: 10px 0px;
        border-top: 2px solid #000000;
        gap: 0px;
        z-index: 10;
        background: rgb(0, 0, 0);
        background: linear-gradient(173deg, rgba(0, 0, 0, 1) 37%, rgba(0, 60, 95, 1) 79%);
    }

    header ul.open {
        display: flex;
    }

    header ul li {
        margin: 10px 0;
        text-align: center;
    }

    .overlay {
        position: fixed;
        top: 82px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }
}