.about {
    background-color: #003C5F;
    padding: 80px 0px;
}

.about .title-section {
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bold;
    margin: 0 auto 30px;
    text-align: center;
}

.about .card {
    display: flex;
    gap: 30px;
    margin: 40px auto 0px;
    max-width: 900px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0);
    border-radius: 10px;
    padding: 30px;
}

.about .card img {
    max-width: 200px;
    margin: 0 auto;
    display: block;
}

.about .card .title {
    color: #ffffff;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    margin: 0px 0px 5px;
}

.about .card .description {
    color: #ffffff;
    font-size: 17px;
    line-height: 23px;
}

.about .card .function {
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin: 0 0 30px;
}

@media (max-width: 767px) {
    .about {
        padding: 40px 0px;
    }

    .about .card {
        flex-direction: column;
    }

    .about .card.colum-reverse {
        flex-direction: column-reverse;
    }

    .about .card img {
        max-width: 240px;
    }
}