.contact {
    background-color: #000000;
    padding: 80px 0px;
}

.contact .d-flex {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
}

.contact .title {
    font-size: 24px;
    line-height: 27px;
    color: #ffffff;
    font-weight: bold;
    margin: 0 auto 50px;
    text-align: center;
    max-width: 800px;
}

.contact .cta-msg {
    color: #ffffff;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    margin: 0px 0px 30px;
}

.contact a {
    display: flex;
    margin: 0px;
    text-align: center;
    background-color: #25D366;
    color: #ffffff;
    text-decoration: none;
    width: 326px;
    padding: 12px;
    border-radius: 5px;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.contact a:hover {
    background-color: #5bd488;
}

@media (max-width: 767px) {
    .contact {
        padding: 40px 0px 80px;
    }

    .contact .d-flex {
        flex-direction: column;
        gap: 30px;
    }

    input,
    textarea {
        width: calc(100% - 24px);
    }

    .contact a {
        width: 100%;
        padding: 12px 0px;
    }

    .contact .title {
        font-size: 22px;
        line-height: 25px;
        max-width: 333px;
        text-align: left;
        margin: 0 0 50px;
    }
}