.form .form-field {
    position: relative;
    margin-bottom: 20px;
}

.form label {
    position: absolute;
    top: 19px;
    left: 12px;
    transform: translateY(-50%);
    background: #000000;
    padding: 0 4px;
    color: #ffffff;
    transition: all 0.3s;
    pointer-events: none;
}

input:focus+label,
textarea:focus+label,
.filled+label {
    top: -2px;
    left: 10px;
    font-size: 12px;
    color: #ffffff;
}

input,
textarea {
    width: 300px;
    padding: 12px;
    margin-bottom: 5px;
    border: 1px solid #696969;
    border-radius: 4px;
    background: transparent;
    color: #ffffff;
    caret-color: #ffffff;
    outline: none;
    transition: border-color 0.3s;
}

input:focus,
textarea:focus {
    border-color: #ffffff;
}

.error-message {
    color: red;
    font-size: 11px;
    margin-top: 0px;
}

.error {
    border-color: red;
}

.form button {
    display: block;
    margin: 0px;
    text-align: center;
    background-color: #003C5F;
    color: #ffffff;
    text-decoration: none;
    width: 326px;
    padding: 12px;
    border-radius: 5px;
    font-size: 17px;
}

.form button:hover {
    background-color: #4682B4;
}

@media (max-width: 767px) {
    .form button {
        width: 100%;
    }
}