.services {
    background-color: #000000;
    color: #ffffff;
    padding: 80px 0px;
    margin: 80px 0px 0px;
}

.services .title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    margin: 0px 0px 40px;
}

.services-quality {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.services-quality .title {
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    margin: 0px 0px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.services-quality .title span {
    font-size: 26px;
}

.services-quality .descripition {
    font-size: 17px;
    line-height: 22px;
}

.services-quality .card {
    border: 2px solid #003C5F;
    padding: 40px 50px;
    box-shadow: -2px 4px 10px rgba(133, 133, 133);
    border-radius: 10px;
}

.services a {
    display: block;
    margin: 80px auto 0px;
    text-align: center;
    background-color: #003C5F;
    color: #ffffff;
    text-decoration: none;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
}

.services a:hover {
    background-color: #4682B4;
}

@media (max-width: 767px) {
    .services {
        padding: 40px 0px;
    }

    .services h1 {
        margin: 0px 0px 20px;
    }

    .services-quality {
        display: block;
    }

    .services-quality .card {
        padding: 20px;
        margin-top: 30px;
    }

    .services a {
        margin: 40px auto 0px;
    }
}